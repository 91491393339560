@tailwind preflight;

@tailwind components;

@import "common/common";

// global styles on the most common elements
html {
    @apply font-sans leading-normal text-black;
}

h1, h2, h3, h4, h5, h6 {
    @apply font-display leading-tight;
}

h1 {
	@apply mt-0 font-black text-3xl uppercase;
	@screen lg {
		@apply text-5xl;
	}
}

p {
    &:first-of-type {
        @apply mt-0;
    }
}

a, a:visited {
    @apply text-blue;
}

.bg-pattern {
	@apply bg-fixed bg-grey-light;
	background-image: url('/images/bkg.png');
}

.text-shadow {
	text-shadow: 0px 1px 2px rgba(0,0,0,0.2);
}

// common styles
@import "common/alerts",
    "common/content_alignment",
    "common/forms",
    "common/modal";

// public only styles
@import "public/buttons",
	"public/herobar",
	"public/header",
	"public/locations",
	"public/services";

// page specific
//@import ;

@tailwind utilities;