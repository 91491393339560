.locations {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: auto;
    grid-gap: 2em;
}

.location-item {
    @apply relative max-w-132 pb-64 border-2 border-blue;
    .cmsmap {
        @apply absolute pin-b h-64 w-full;
    }


    .button {
        position: absolute;
        left: 5rem;
        right: 5rem;
        bottom: 18rem;
    }
}