.header-nav_item {
	@apply hidden mb-4;
	&:last-of-type {
		@apply mb-0;
	}
	@screen lg {
		@apply inline-block mr-12 mb-0;
	}
    
}

a.header-nav_item-link {
	@apply font-bold text-white no-underline;
}

.header-menu-icon {
	position: absolute;
	top: 1rem;
	right: 2rem;
}

.header_mobile-open {
	@apply relative pt-6 pb-4;
	.header-nav-wrap {
		@apply block;
	}
	.header-nav_item {
		@apply block;
	}
	
}