.button {
	@apply px-8 py-4 text-blue border-2 border-blue no-underline;
	transition: all 0.25s ease;
	&:hover {
		transform: translateY(-1px);
        box-shadow: 0px 1px 2px rgba(0,0,0,0.5);
	}
}

.button-small {
    @apply text-sm px-4 py-2 border;
}
