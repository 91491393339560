.herobar {
	@apply py-12 bg-cover bg-center;
	@screen lg {
		@apply bg-right;
	}
}

.herobar-home {
	background-image: url('/images/penticton-hearing-aid-clinic.jpg');
	
}

.herobar-about {
	background-image: url('/images/about-okanagan-hearing-aid-clinic.jpg');
}



.herobar-contact {
	background-image: url('/images/contact-okanagan-hearing-aid-clinic.jpg');
}

.banner {
	@apply py-32 bg-cover bg-center;
	@screen lg {
		@apply py-48;
	}
}

.banner-about {
    background-image: url('/images/hearing-aid-clinic-penticton.jpg');
}

.banner-services {
	@apply bg-right;
    background-image: url('/images/services-penticton-hearing-aid-clinic.jpg');
}